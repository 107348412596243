<template>
  <div class="sidebar-container">
    <div class="sidebar-top">
      <img src="~static/images/logo/logo.png">
      <span>{{ currentSystemName }}</span>
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :show-timeout="200"
        :default-active="defaultActive"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :active-text-color="variables.menuActiveText"
        mode="vertical"
      >
        <sidebar-item v-for="route in menuList" :key="route.path" :item="route"/>
      </el-menu>
    </el-scrollbar>
    <!-- <div class="sidebar-footer" @click="$router.push({ name: 'main' })">
      <div class="home-icon">
        <img src="~static/images/system/menu-home-icon.png">
      </div>
      <div>返回首页</div>
      <div class="back-icon">
        <img src="~static/images/system/menu-back-icon.png">
      </div>
    </div> -->
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import * as variables from '@/styles/variables.scss'
import SidebarItem from './SidebarItem'
import { getStorage } from '@/libs/utils'

export default {
  components: { SidebarItem },
  data() {
    return {
      menuList: [],
      defaultActive: '',
      currentSystem: getStorage('activeSystem')
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'systems',
      'activeSystem',
      'activeSystemData'
    ]),
    routes() {
      return this.$router.options.routes
    },
    variables() {
      return variables
    },
    currentSystemName() {
      const system = this.systems.find(item => item.resourcePath === this.activeSystem)
      return system ? system.resourceName : ''
    }
  },
  watch: {
    // 切换系统 重新加载菜单
    activeSystem: {
      handler: function(val) {
        const { routes } = this.$router.options
        this.menuList = this.getMenuList(routes, val)
      },
      deep: true,
      immediate: true
    },
    // 理由切换，获取当前选中路由数据
    $route: {
      handler: function() {
        this.defaultActive = this.getDefaultActive()
      },
      immediate: true
    },
    systems: {
      handler() {
        const { routes } = this.$router.options
        this.menuList = this.getMenuList(routes, this.activeSystem)
      }
    }
  },
  methods: {
    getMenuList(routes, currentSystem) {
      const mainRoutes = routes.filter(item => item.path === '/plat')
      let systemData = null
      if (mainRoutes[0] && mainRoutes[0].children) {
        systemData = mainRoutes[0].children.find(item => item.name === currentSystem)
      }
      const showMenu = systemData ? systemData.children : []
      return showMenu
    },
    getDefaultActive() {
      let active = ''
      const meta = this.$route.meta
      if (meta && meta.parentPath) {
        active = meta.parentPath
      } else {
        active = this.$route.path
      }
      return active
    }
  }
}
</script>
