import Vue from 'vue'
import IconBtn from './IconBtn'
import NavRight from './NavRight'
import OrgTree from './OrgTree'

const install = function(Vue, options = {}) {
  // 静态方法
  if (install.installed) return
  install.installed = true
  // 全局组件
  Vue.component('IconBtn', IconBtn)
  Vue.component('NavRight', NavRight)
  Vue.component('OrgTree', OrgTree)
}
var components = { install }

Vue.use(components)

export default { components }
