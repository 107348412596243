<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    selectedIcon: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const { icon, selectedIcon, title } = context.props
    const vnodes = []

    if (icon) {
      // vnodes.push(<svg-icon icon-class={icon}/>)
      vnodes.push(<img src={icon} width='18' height='18' class='def_icon'/>)
    }
    if (selectedIcon) {
      // vnodes.push(<svg-icon icon-class={icon}/>)
      vnodes.push(<img src={selectedIcon} width='18' height='18' class='selected_icon'/>)
    }
    if (!icon && !selectedIcon) {
      vnodes.push(<span style='width: 8px;display:inline-block'></span>)
    }

    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>
