<template>
  <div :class="[theme]" class="nav-right">
    <el-select v-model="currentSystem" @change="changeSelectSystemMenu">
      <el-option v-for="item in systems" :key="item.resourcePath" :value="item.resourcePath" :label="item.resourceName"></el-option>
    </el-select>
    <!-- <div class="system-change">
      <el-select v-if="selectMode === 'select'" v-model="currentSystem" @change="changeSelectSystemMenu">
        <el-option v-for="item in systems" :key="item.url" :value="item.url" :label="item.resourceName"></el-option>
      </el-select>
      <el-popover
        v-if="selectMode === 'dropdown'"
        v-model="showPop"
        placement="bottom-start"
        width="600"
        trigger="click"
        popper-class="entry-body-popper"
        @show="show"
        @after-leave="afterLeave">
        <EntryBody v-if="showPop" @changeSystemMenu="changeSystemMenu"></EntryBody>
        <div class="go-more">
          <span @click="goEntry">更多 <i class="el-icon-arrow-right"></i></span>
        </div>
        <div slot="reference" class="dropdown-system">
          <span class="ellipsis">{{ currentSystemName }}</span>
          <i class="el-icon-arrow-down"></i>
        </div>
      </el-popover>
    </div> -->
    <div class="user-info">
      <!-- <img src="~static/images/app/up_cloud.png" class="menu-icon">
      <img src="~static/images/app/message.png" class="menu-icon"> -->
      <div class="avatar">
        <img v-if="userInfo.headPicture" :src="assetsPath(userInfo.headPicture)" class="avatar-image" >
        <img v-else src="~static/images/default/photo.png" class="avatar-image" >
        <span class="username">{{ userInfo.username }}</span>
      </div>
      <img src="~static/images/app/logout.png" @click="logout" class="menu-icon">
      <!-- <el-dropdown trigger="hover" class="btn-setting">
        <i class="el-icon-setting"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><div @click="updatePassword">修改密码</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { changeSystemMenu } from '@/router/util'
import { assetsPath, getStorage } from '@/libs/utils'
// import EntryBody from '@/views/main/components/EntryBody.vue'
import resetPass from './resetPass.vue'

export default {
  components: {
    // EntryBody
  },
  props: {
    theme: {
      type: String,
      default: 'dark' //  dark || light
    },
    selectMode: {
      type: String,
      default: 'select' // select || dropdown
    }
  },
  data() {
    return {
      currentSystem: getStorage('activeSystem'),
      showPop: false
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'systems',
      'activeSystem',
      'activeSystemData'
    ]),
    currentSystemName() {
      const system = this.systems.find(item => item.url === this.activeSystem)
      return system ? system.resourceName : ''
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.showPop = false
      }
    }
  },
  mounted() {
  },
  methods: {
    assetsPath: assetsPath,
    logout() {
      this.$confirm('退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      })
    },
    changeSelectSystemMenu(val) {
      if (val === 'home') {
        this.$router.push({ name: 'homeScreen' })
        return
      }
      const find = this.systems.find(item => item.resourcePath === val)
      this.changeSystemMenu(find)
    },
    async changeSystemMenu(val) {
      await this.$store.dispatch('SetActiveSystemData', val)
      changeSystemMenu(val)
    },
    goEntry() {
      this.$router.push({ name: 'main' })
    },
    // 修改密码
    updatePassword() {
      this.$qkDialog({
        title: '修改密码',
        components: resetPass,
        width: 460,
        onAction: (action) => {
          if (action === 'success') {
            // 退出重新登录
            this.$store.dispatch('LogOut').then(() => {
              location.reload() // 为了重新实例化vue-router对象 避免bug
            })
          }
        }
      })
    },
    show() {
      this.showPop = true
    },
    afterLeave() {
      this.showPop = false
    }
  }
}
</script>
<style lang="scss">
.entry-body-popper {
  padding: 20px;
  .entry-body-container {
    padding: 0;
    width: 100%;
    .entry-item-wrapper {
      height: auto;
    }
    .entry-card-title {
      margin-top: 20px;
    }
    .entry-item-image {
      margin: 10px;
    }
    .entry-item-name {
      margin: 10px 0;
    }
  }
  .go-more {
    width: 100%;
    text-align: right;
    padding: 10px 10px 0 0;
    color: #ccc;
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "src/styles/mixin.scss";
.nav-right {
  height: 100%;
  display: flex;
  align-items: center;
  .system-change {
    display: inline-block;
  }
  .user-info {
    display: inline-flex;
    align-items: center;
    .menu-icon {
      width: 20px;
      height: 20px;
      margin: 0 6px;
      cursor: pointer;
    }
    .avatar {
      display: flex;
      align-items: center;
      padding: 0 16px;
    }
    .avatar-image{
      margin-right: 10px;
    }
    .avatar-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .username {
      display: inline-block;
      max-width: 60px;
      @include ellipsis
    }
    .logout {
      padding: 0 5px;
      cursor: pointer;
    }
  }
  .dropdown-system {
    position: relative;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    height: 32px;
    line-height: 32px;
    padding: 0 35px 0 15px;
    font-size: 13px;
    cursor: pointer;
    span {
      display: inline-block;
      width: 120px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.nav-right.dark {
  .system-change {
    ::v-deep .el-select {
      input {
        background: rgba(18, 75, 105, 0.5)!important;
        border: 0px;
        color: white;
      }
    }
  }
}
.btn-setting {
  display: flex;
  align-items: center;
  height: 18px;
}
.entry-top-container {
  .el-icon-setting {
    color: #fff;
  }
}
.el-icon-setting {
  font-size: 18px;
  margin-left: 5px;
  vertical-align: -1px;
  height: 18px;
}
</style>
