const app = {
  state: {
    resourceId: {}
  },
  mutations: {
    SET_RESOURCE_ID: (state, resourceId) => {
      state.resourceId = resourceId
    }
  },
  actions: {
    SetResourceId({ commit }, resourceId) {
      commit('SET_RESOURCE_ID', resourceId)
    }
  }
}

export default app
