import 'babel-polyfill'
import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import 'animate.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '../static/font/icon/iconfont.css'

// 自定义公共组件
import QuickUI from 'incar-quick-ui'
import 'incar-quick-ui/lib/incar-quick-ui.css'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './permission'

import '@/icons' // icon
import '@/components' // global components
import '@/libs/directive' // directive
import * as filters from './libs/filters' // global filters

import VueWechatTitle from 'vue-wechat-title'
import * as utils from '@/libs/utils/index'

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

Vue.use(VueWechatTitle)
// 自定义过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.prototype.$utils = utils

Vue.use(ElementUI)
// 指定 iconfont
Vue.use(QuickUI, { iconfont: 'v2x-icon', searchBtnText: true })

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
