import _ from 'lodash'
import { login, logout } from '@/api/login'
import { getUserInfo, getUserMenuTree } from '@/api/user'
import { setStorage, getStorage, removeStorage } from '@/libs//utils'

import { generateRoutes, getMenuList } from '@/router/util'

const user = {
  state: {
    allSystems: [],
    systems: [], // 用户所拥有系统权限
    activeSystem: getStorage('activeSystem'), // 当前激活系统名称
    activeSystemData: getStorage('activeSystemData'), // 当前激活系统信息
    userInfo: {},
    roles: [],
    menuList: null,
    waitDownloadNumber: 0,
    authList: []
  },
  getters: {
    allSystems(state) {
      return state.allSystems
    }
  },
  mutations: {
    SET_USER: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_MENU_LIST: (state, menuList) => {
      state.menuList = menuList
    },
    SET_WAIT_DOWNLOAD_NUMBER: (state, data) => {
      state.waitDownloadNumber = data
    },
    SET_SYSTEMS: (state, data) => {
      state.systems = data
    },
    SET_ACTIVE_SYSTEM: (state, data) => {
      state.activeSystem = data
    },
    SET_ACTIVE_SYSTEM_DATA: (state, data) => {
      state.activeSystemData = data
    },
    SET_AUTH_LIST: (state, data) => {
      state.authList = data
    }
  },
  actions: {
    // 登录
    Login({ commit }, loginDto) {
      return new Promise((resolve, reject) => {
        login(loginDto).then(response => {
          const data = response.data
          setStorage('_id', data ? (data.id || '') : '')
          commit('SET_USER', data || {})
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          removeStorage('token')
          removeStorage('activeSystem')
          commit('SET_USER', {})
          commit('SET_ROLES', [])
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        removeStorage('token')
        removeStorage('activeSystem')
        commit('SET_USER', {})
        commit('SET_ROLES', [])
        resolve()
      })
    },

    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(response => {
          const data = response.data
          if (response.statusCode === 200) {
            commit('SET_USER', data || {})
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          removeStorage('token')
          reject(error)
        })
      })
    },
    GetResourceList({ commit, state }, router) {
      return new Promise((resolve, reject) => {
        // getResourceList
        getUserMenuTree().then(res => {
          let origin = res.data || []
          const authList = []
          // 获取所有菜单 去除 tab btn
          state.allSystems = _.cloneDeep(origin)
          const menuList = getMenuList(_.cloneDeep(origin))
          commit('SET_SYSTEMS', menuList)
          commit('SET_MENU_LIST', menuList)
          commit('SET_AUTH_LIST', authList)
          generateRoutes(menuList)
          resolve(menuList)
        }).catch(error => {
          reject(error)
        })
      })
    },
    UpdateUserInfo({ commit }, userInfo) {
      commit('SET_USER', userInfo || {})
    },
    SetActiveSystemData({ commit, state }, sys) {
      return new Promise((resolve, reject) => {
        if (!sys) {
          resolve(null)
          return
        }
        commit('SET_ACTIVE_SYSTEM', sys.url || '')
        commit('SET_ACTIVE_SYSTEM_DATA', sys)
        // setStorage('activeSystemData', sys) // 持久化
        setStorage('activeSystem', sys.url || '') // 持久化
        resolve(sys)
      })
    }
  }
}

export default user
