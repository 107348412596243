<template>
  <div class="delete-confirm-dialog">
    <div class="confirm-img">
      <img src="./images/delete-confirm.png" width="132" height="132" />
    </div>
    <div class="confirm-text">
      删除之后将无法恢复数据，请确定是否删除
    </div>
    <div class="qk-dialog-form-footer">
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    submit() {
      this.onAction('confirm')
      this.onClose()
    },
  }
}
</script>

<style lang="scss">
.delete-confirm-dialog {
  text-align: center;
  .config-img {
    padding: 20px;
  }
  .confirm-text {
    color: #334458;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .qk-dialog-form-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 25px;
    height: 60px;
    border-top: 1px solid rgba(220, 223, 230, .714);
  }
}
</style>
