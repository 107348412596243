// import _ from 'lodash'
import crypto from 'crypto-js';
import { Message } from 'element-ui'
import DeleteConfirmDialog from '@/components/DeleteConfirmDialog/index.vue'

export { fileToUrl, getResourceByUrl, downloadBolb, downloadFile, downloadUrl } from './file'

export { dateTimeFormat, dateFormat, handlerRangeTime } from './date'

export function assetsPath(path) {
  let baseUrl = location.hostname
  if (baseUrl === 'localhost') {
    baseUrl = 'demo.gmmc.incarcloud.com'
  }
  return location.protocol + '//' + baseUrl + path
}

/** 调用浏览器storage
 * @param key
 * @param value
 * @param boolean
 */
export function setStorage(key, value, boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.sessionStorage
  } else {
    storageObj = window.localStorage
  }
  if (Object.prototype.toString.call(value) === '[object Object]' || Object.prototype.toString.call(value) === '[object Array]') {
    value = JSON.stringify(value)
  }
  storageObj.setItem(key, value)
}

export function getStorage(key, boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.sessionStorage
  } else {
    storageObj = window.localStorage
  }
  return storageObj.getItem(key)
}
export function removeStorage(key, boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.sessionStorage
  } else {
    storageObj = window.localStorage
  }
  storageObj.removeItem(key)
}
export function getQueryString(url) {
  if (!url.indexOf('?')) return
  const qs = url.substr(url.indexOf('?') + 1) // 获取url中"?"符后的字串
  const args = {} // 保存参数数据的对象
  const items = qs.length ? qs.split('&') : [] // 取得每一个参数项,
  let item = null
  const len = items.length
  for (var i = 0; i < len; i++) {
    item = items[i].split('=')
    const name = decodeURIComponent(item[0])
    const value = decodeURIComponent(item[1])
    if (name) {
      args[name] = value
    }
  }
  return args
}
/**
 * disablesConfirm
 * @param {*} submitFun
 * @param {*} cancelFun
 */
export function disablesConfirm(submitFun, cancelFun) {
  this.$confirm(this.$t('app.disablesTips'), this.$t('app.disables'), {
    confirmButtonText: this.$t('app.submit'),
    cancelButtonText: this.$t('app.cancel'),
    type: 'warning',
    confirmButtonClass: 'show-del-submit'
  }).then(() => {
    submitFun && submitFun()
  }).catch(() => {
    cancelFun && cancelFun()
  })
}
/**
 * disablesConfirm
 * @param {*} submitFun
 * @param {*} cancelFun
 */
export function enableConfirm(submitFun, cancelFun) {
  this.$confirm(this.$t('app.enableTips'), this.$t('app.enable'), {
    confirmButtonText: this.$t('app.submit'),
    cancelButtonText: this.$t('app.cancel'),
    type: 'warning',
    confirmButtonClass: 'show-del-submit'
  }).then(() => {
    submitFun && submitFun()
  }).catch(() => {
    cancelFun && cancelFun()
  })
}
/**
 * deleteConfirm
 * @param {*} submitFun
 * @param {*} cancelFun
 */
export function deleteConfirm(submitFun, cancelFun) {
  this.$confirm(this.$t('app.delTips'), this.$t('app.delete'), {
    confirmButtonText: this.$t('app.submit'),
    cancelButtonText: this.$t('app.cancel'),
    type: 'warning',
    confirmButtonClass: 'show-del-submit'
  }).then(() => {
    submitFun && submitFun()
  }).catch(() => {
    cancelFun && cancelFun()
  })
}

export function deleteDialog() {
  return new Promise((resolve, reject)=> {
    this.$qkDialog({
      components: DeleteConfirmDialog,
      title: '警告提示',
      width: 530,
      onAction: (action) => {
        if (action === 'confirm') {
          resolve()
        } else {
          reject()
        }
      }
    })
  })
}
/**
 * deleteConfirm
 * @param {*} submitFun
 * @param {*} cancelFun
 */
export function unbindConfirm(submitFun, cancelFun) {
  this.$confirm(this.$t('app.unbind'), this.$t('app.unbind'), {
    confirmButtonText: this.$t('app.submit'),
    cancelButtonText: this.$t('app.cancel'),
    type: 'warning',
    confirmButtonClass: 'show-del-submit'
  }).then(() => {
    submitFun && submitFun()
  }).catch(() => {
    cancelFun && cancelFun()
  })
}

export function buildMenuList(menuList, routesMap, childRouterMap) {
  // 获取其他子级路由 获取childRouterMap中指定父级name的路由
  function otherChildRouter(name) {
    const childs = childRouterMap.filter(item => item.parentName === name)
    if (childs && childs.length) {
      return childs.map(item => {
        return {
          ...item,
          hidden: true,
          path: item.name
        }
      })
    }
    return []
  }
  // 处理正常路由
  function handleMenuData(menus, level) {
    return menus.map(item => {
      const ret = {
        name: item.url,
        meta: { title: item.title, icon: item.icon, selectedIcon: item.selectedIcon },
        title: item.title
      }
      if (level === 0) {
        ret.path = '/' + item.url
        ret.component = () => import('@/views/layout/Layout')
      } else {
        ret.path = item.url
        ret.component = routesMap[item.url] ? routesMap[item.url] : () => import('@/noPage')
        if (item.childrens && item.childrens.length) {
          ret.component = () => import('@/routeView')
          ret.children = handleMenuData(item.childrens)
        }
      }
      if (item.childrens && item.childrens.length) {
        ret.children = handleMenuData(item.childrens)
      }
      const childs = otherChildRouter(ret.name)
      if (childs.length) {
        if (ret.children) {
          ret.children.push(...childs)
        } else {
          ret.children = childs
        }
      }
      return ret
    })
  }
  // 获取其他路由   childRouterMap 中没指定父级的路由
  function otherRouter() {
    let noParent = childRouterMap.filter(item => !item.parentName)
    noParent = noParent.map(item => {
      return {
        ...item,
        path: item.name
      }
    })
    const others = {
      path: '',
      component: () => import('@/views/layout/Layout'),
      name: 'othersRouter',
      hidden: true,
      children: [
        ...noParent
      ]
    }
    return others
  }
  const others = otherRouter()
  const menus = handleMenuData(menuList, 0)
  menus.push(others)
  return menus
}
/**
 * 判断是否全屏
 * @returns 是否全屏
 */
export function isFullscreen() {
  var explorer = window.navigator.userAgent.toLowerCase()
  if (explorer.indexOf('chrome') > 0) { // webkit
    if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
      return true
    } else {
      return false
    }
  } else { // IE 9+  fireFox
    if (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width) {
      return true
    } else {
      return false
    }
  }
}

export function setLastSearch(type, data, lastNum = 5) {
  if (!data) return
  const local = JSON.parse(getStorage(type)) || []
  if (local.includes(data)) {
    const index = local.indexOf(data)
    local.splice(index, 1)
  }
  if (local.length < lastNum) {
    local.unshift(data)
  } else {
    local.pop()
    local.unshift(data)
  }
  setStorage(type, JSON.stringify(local))
}
export function getLastSearch(type) {
  return JSON.parse(getStorage(type)) || []
}

/**
 * @param {*} config
 * {
 *  message 成功提示消息,
 *  success 成功回调函数,
 *  error 成功回调函数
 * }
 * @returns
 */
export const handlerResponse = function(config = { message: '', success: null, error: null }) {
  const { message, success, error } = config
  return function(res) {
    if (res.statusCode === 200) {
      Message.success(message || '操作成功')
      success && typeof success === 'function' && success()
    } else {
      Message.error(res.message || '操作失败')
      error && typeof error === 'function' && error()
    }
    return res
  }
}
/**
 * 检测文件后缀是否符合要求
 * @param {*} fileName 文件名 or 文件地址
 * @param {*} suffix 后缀
 * @returns Boolean
 */
export const checkFileSuffix = function(fileName, suffix) {
  const objType = Object.prototype.toString.call(suffix)
  const index = fileName.lastIndexOf('.')
  const text = fileName.substring(index + 1)
  if (objType === '[object String]') {
    return suffix === text
  } else if (objType === '[object Array]') {
    return suffix.includes(text)
  }
  return false
}
// 处理查询参数
export function getParams(search, page) {
  const { formData } = search || {}
  if (formData) {
    // 过滤空数据
    Object.keys(formData).map(key => {
      const v = search[key]
      if (!(v || v === 0)) {
        delete search[key]
      }
    })
  }
  return { ...(formData || {}), ...{ offset: page.num, limit: page.size }}
}


export function encryptPass (pwd) {
  const sha256 = crypto.SHA256(pwd);
  const md5 = crypto.MD5(sha256.toString())
  return md5.toString()
}