
import request from '@/libs/utils/request'
/**
 * 获取用户信息
 * @returns
 */
export const getUserInfo = () => request.get(`/api/v1/plat/base/user/getMe`)
/**
 * 获取所有菜单信息
 * @returns 
 */
export const getAllMenuList = () => request.get(`/api/v1/plat/base/resource/getTree`)
/**
 * 获取当前用户拥有的菜单
 * @returns 
 */
export const getUserMenuTree = () => request.get(`/api/v1/plat/base/resource/getMe`)
/**
 * 获取菜单资源
 * @returns
 */
export const getResourceList = () => request.get(`/api/sys/resource/getUserResourceTree`)

/**
 * 获取角色菜单资源id
 * @returns
 */
export const getRoleResourceIds = (params) => request.get(`/api/v1/plat/base/resource/getRoleResourceIds`, { params })
/**
 * 获取角色菜单资源树
 * @returns
 */
export const getRoleResourceTree = (params) => request.get(`/api/v1/plat/base/resource/getRoleResource`, { params })
/**
 * 获取角色列表
 * @returns
 */
export const getRoleList = (params) => request.get(`/api/plat/user/getRolesByPlat`, { params })

export const findMenuResourceTree = () => request.get(`/api/v1/plat/base/resource/getTree`)

export const updatePassword = (payload) => request.put('/api/plat/user/updatePassword', payload)

export const logExportRecord = (payload) => request.post('/api/plat/logExportRecord/add', payload)

