import _ from 'lodash'
import dayjs from "dayjs"
export function dateTimeFormat(str, fmt = 'YYYY-MM-DD HH:mm:ss') {
  return dateFormat(str, fmt)
}
/**
 * 格式化日期
 * @param  {[type]} fmt [description]
 * @return {[type]}     [description]
 */
export function dateFormat(str, fmt = 'YYYY-MM-DD') {
  if (_.isNil(str)) return ''
  if (_.isNaN(Number(str))) {
    return dayjs(str).format(fmt)
  }
  return dayjs(Number(str)).format(fmt)
}
/**
 * 处理
 */
export function handlerRangeTime (formData, rangeTimes) {
  rangeTimes.forEach(item => {
    const value = formData[item.key]
    if (value && value.length > 0) {
      if (item.format) {
        formData[item.startKey] = dayjs(value[0]).format(item.format)
        formData[item.endKey] = dayjs(value[1]).format(item.format)
      } else {
        formData[item.startKey] = dayjs(value[0]).valueOf()
        formData[item.endKey] = dayjs(value[1]).valueOf()
      }
      delete formData[item.key]
    }
  })
}