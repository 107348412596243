import { getDictList } from '@/api/dict'
import { setOptionsFilter } from '@/libs/options'
import dictData from '@/constants/dict'

const dict = {
  state: {
    dicts: new Map()
  },
  mutations: {
    SET_DICT: (state, dicts) => {
      state.dicts = dicts
    }
  },
  actions: {
    // 登录
    GetDict({ commit }) {
      return new Promise((resolve, reject) => {
        // 本地字典
        const dicts = new Map()
        const dictKeys = Object.keys(dictData)
        for (let i = 0; i < dictKeys.length; i++) {
          const key = dictKeys[i];
          dicts.set(key, dictData[key])
        }
        setOptionsFilter(dicts)
        commit('SET_DICT', dicts)
        resolve(dicts)

        // 后台数据字典
        // getDictList().then(response => {
        //   const data = response.data || []
        //   const dicts = new Map()
        //   for (let i = 0; i < data.length; i++) {
        //     const item = data[i]
        //     const dict = dicts.get(item.key)
        //     delete item.id
        //     if (dict) {
        //       dict.push(item)
        //     } else {
        //       dicts.set(item.key, [item])
        //     }
        //   }
        //   setOptionsFilter(dicts)
        //   commit('SET_DICT', dicts)
        //   resolve(dicts)
        // }).catch(error => {
        //   reject(error)
        // })
      })
    }
  }
}

export default dict
