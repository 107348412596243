<template>
  <div id="app">
    <router-view v-wechat-title="appTitle"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    appTitle() {
      return this.$route.meta.title || ''
    }
  }
}
</script>
