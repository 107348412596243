export default {
  orgType: [
    { value: 0, label: '本中心' },
    { value: 1, label: '非本中心' }
  ],
  sex: [
    // { value: 0, label: '未知' },
    { value: 1, label: '男' },
    { value: 2, label: '女' }
  ],
  userStatus: [
    { value: 0, label: '禁用' },
    // { value: 1, label: '锁定' },
    { value: 2, label: '正常' },
  ],
  dockingTaskType: [
    { value: 1, label: '小程序呼叫' },
    { value: 2, label: '系统接驳' },
  ],
  dockingTaskState: [
    { value: 0, label: '呼叫成功' },
    { value: 1, label: '进行中' },
    { value: 2, label: '呼叫取消' },
    { value: 3, label: '已完成' }
  ],
  adState: [
    { value: true, label: '可见' },
    { value: false, label: '不可见' },
  ],
  vehicleModel: [
    { value: '1', label: '接驳小巴' },
    { value: '2', label: '零售小巴' },
    { value: '3', label: '清扫小车' },
  ],
  loginType: [
    { value: 0, label: '登录' },
    { value: 1, label: '登出' },
  ],
  // productType: [
  //   { value: '雷视一体机', label: '雷视一体机' },
  //   { value: '激光雷达', label: '激光雷达' },
  //   { value: '边缘计算设备', label: '边缘计算设备' },
  //   { value: '高清红外球机', label: '高清红外球机' },
  //   { value: '违停球机', label: '违停球机' },
  //   { value: '结构化相机', label: '结构化相机' },
  //   { value: '行人检测相机', label: '行人检测相机' },
  //   { value: '交通信息发布屏', label: '交通信息发布屏' },
  // ],
  accessProtocol: [
    { value: 'TCP', label: 'TCP' },
    { value: 'MQTT', label: 'MQTT' },
    { value: 'UDP', label: 'UDP' },
    { value: 'HTTP', label: 'HTTP' },
    { value: 'CAN', label: 'CAN' },
  ],
  dataProtocol: [
    { value: 'XML', label: 'XML' },
    { value: 'JSON', label: 'JSON' },
  ]
}