const resourceUrl = '/v2x-dev'

function download(url) {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.click()
  window.URL.revokeObjectURL(url) // 释放该 url
}

export function getResourceByUrl(url) {
  return `${resourceUrl}/${url}`
}

export function fileToUrl(file) {
  const windowURL = window.URL || window.webkitURL
  return windowURL.createObjectURL(file)
}
/**
 * 下载Bolb
 * @param {*} bolb
 * @param {*} name
 */
export function downloadBolb(bolb, config) {
  const blobData = new Blob([bolb], config || { type: 'application/vnd.ms-excel' })
  const url = window.URL.createObjectURL(blobData) // 创建 url 并指向 blob
  download(url)
}
/**
 * 下载文件
 * @param {*} file
 */
export function downloadFile(file) {
  const url = fileToUrl(file)
  download(url)
}
/**
 * 通过地址下载
 * @param {*} url
 */
export function downloadUrl(url) {
  download(getResourceByUrl(url))
}
