/* Layout */
import Layout from '../views/layout/Layout'
// import routeView from '@/routeView'

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
  }
**/

export const defaultRoutes = [
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */ '@/views/main/index'),
    meta: {
      title: '首页'
    },
    hidden: true
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index'),
    meta: {
      title: '云控平台'
    },
    hidden: true
  },
  // {
  //   path: '/test',
  //   meta: {},
  //   component: Layout,
  //   // 资源接口调用成功后删除children
  //   children: [
  //     { 
  //       path: 'site',
  //       component: () => import(/* webpackChunkName: "site" */ '@/views/pages/minibusSystem/site/index')
  //     }
  //   ]
  // },
  {
    path: '/plat',
    meta: {},
    component: Layout,
    // 资源接口调用成功后删除children
    children: []
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404'),
    hidden: true
  }
]
