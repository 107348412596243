const alarm = {
  state: {
    sendParams: {}
  },
  mutations: {
    SET_SEND_PARAMS: (state, sendParams) => {
      state.sendParams = sendParams
    }
  },
  actions: {
    SetSendParams({ commit }, sendParams) {
      commit('SET_SEND_PARAMS', sendParams || {})
    }
  }
}

export default alarm
