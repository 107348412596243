<template>
  <div class="breadcrumb-wrapper">
    <el-breadcrumb class="app-breadcrumb" separator="/">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="(item) in levelList" :key="item.path">
          <span class="no-redirect">{{ item.meta.title }}</span>
        <!-- <span v-if="item.redirect==='noredirect'||index==levelList.length-1" class="no-redirect">{{ item.meta.title }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a> -->
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
    <!-- <div v-if="toMonitorShow" :class="{'disable-btn': disableBtn}" class="to-monitor" @click="toMonitor()">
      <icon-btn :content="$t('route.assetsRealtimeMonitor')" :color="disableBtn ? '#D1D1D1' : '' " background-color="#2a6aff00" icon="real_monitor" size="18"></icon-btn>
    </div> -->
  </div>
</template>

<script>
// import bus from '@/libs/utils/bus'
import pathToRegexp from 'path-to-regexp'

export default {
  data() {
    return {
      levelList: null,
      disableBtn: true
    }
  },
  computed: {
    // toMonitorShow() {
    //   return ['assetsSignalQuery', 'assetsUseRecord', 'batteryCirculationRecord', 'batteryBaseInfo'].includes(this.$route.name)
    // }
  },
  watch: {
    $route() {
      // this.disableBtn = true
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
    // bus.$on('watchSearchData', (data) => {
    //   this.disableBtn = !data
    //   console.log(this.disableBtn)
    // })
  },
  beforeDestroy() {
    // bus.$off('watchSearchData')
  },
  methods: {
    getBreadcrumb() {
      const matched = this.$route.matched.filter(item => item.name)

      const first = matched[0]
      if (first && first.name !== 'dashboard') {
        // matched = [{ path: '/dashboard', meta: { title: 'Dashboard' }}].concat(matched)
      }

      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
    // toMonitor() {
    //   console.log(this.disableBtn)
    //   if (this.disableBtn) return
    //   bus.$emit('toMonitor')
    // }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .breadcrumb-wrapper {
    display: flex;
    align-items: center;
  }
  .app-breadcrumb.el-breadcrumb {
    padding: 0.5rem 1rem;
    font-size: 14px;
    line-height: 30px;
    .no-redirect {
      color: #97a8be;
      cursor: text;
    }
  }
  ::v-deep .to-monitor {
    .newIconBtn {
      margin: 0;
      padding: 0;
    }
    &.disable-btn{
      .svg-icon{
        cursor: not-allowed;
      }
    }
  }
</style>
