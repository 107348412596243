<template>
  <!-- org-tree -->
  <div v-clickoutside="handleClickOutside" id="org-tree" class="org-tree" style="position: relative">
    <el-input slot="reference" :placeholder="placeholder" v-model="checkedStr" :clearable="clearable" :disabled="disabled" type="text" @focus="togglePopover">
      <svg-icon slot="suffix" icon-class="file-tree"/>
    </el-input>
    <transition name="el-zoom-in-top"> <!-- el-zoom-in-top by element-ui transiton.scss-->
      <div v-show="visible" :style="{width: clientWidth}" style="position: fixed;z-index: 99999;">
        <el-scrollbar ref="scrollbar" class="tree-data">
          <el-tree
            ref="tree"
            :data="data"
            :props="defaultProps"
            :filter-node-method="filterTree"
            :default-checked-keys="defaultKeys"
            check-strictly
            node-key="id"
            default-expand-all
            show-checkbox
            @check="getChecked"
          />
        </el-scrollbar>
      </div>
    </transition>
  </div>
</template>

<script>
import Clickoutside from 'element-ui/src/utils/clickoutside'
import { valueEquals } from 'element-ui/src/utils/util'
import { getOrgTree } from '@/api/org'
export default {
  directives: { Clickoutside },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [Object, String, Array, Number],
      default: function() {}
    },
    label: {
      type: [String, Array],
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    clearable: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      data: [],
      visible: false,
      filterText: '',
      defaultProps: {
        children: 'child',
        label: 'fullName'
      },
      defaultKeys: [],
      checkedKeys: [],
      checkedStr: '',
      checkValue: null,
      clientWidth: '200px'
    }
  },
  // computed: {
  //   checkStrictly() {
  //     return !this.multiple
  //   }
  // },
  watch: {
    checkedStr(val) {
      if (!val) {
        this.$refs.tree.setCheckedNodes([])
      }
    },
    filterText(val) {
      this.$refs.tree && this.$refs.tree.filter(val)
    },
    visible(val) {
      if (!val) return
      this.filterText = ''
      this.setElScrollTop()
    },
    value: {
      handler: function(val, old) {
        // 判断是否改变
        if (valueEquals(val, old)) {
          return
        }
        if (val) {
          if (!Array.isArray(val)) {
            this.defaultKeys = [val]
          } else {
            this.defaultKeys = val
          }
        }
        this.$nextTick(() => {
          this.handleData()
        })
      },
      deep: true,
      immediate: true
    },
    params: {
      handler: function () {
        this.getOrgTree()
      },
      deep: true,
    }
  },
  created() {
    this.getOrgTree()
  },
  mounted() {
    this.clientWidth = document.getElementById('org-tree').clientWidth + 'px'
  },
  methods: {
    getOrgTree() {
      getOrgTree(this.params).then(res => {
        this.data = res.data
        if (this.value) {
          if (!Array.isArray(this.value)) {
            this.defaultKeys = [this.value]
          } else {
            this.defaultKeys = this.value
          }
        }
        console.log(this.defaultKeys);
        this.$nextTick(() => {
          this.handleData()
        })
      })
    },
    setElScrollTop() {
      const div = this.$refs['scrollbar'].$refs['wrap']
      this.$nextTick(() => {
        div.scrollTop = 0
      })
    },
    togglePopover() {
      if (this.disabled) return
      this.visible = true
    },
    handleClickOutside() {
      if (!this.visible) return
      this.visible = false
      this.handleData()
      if (!this.multiple) {
        this.$emit('input', this.checkValue[0])
      } else {
        this.$emit('input', this.checkValue)
      }
    },
    handleData() {
      const tree = this.$refs.tree
      this.checkNodes = tree.getCheckedNodes()
      this.checkValue = this.checkNodes.map(item => item.id)
      this.checkedStr = this.checkNodes.map(item => item.fullName).toString()
    },
    filterTree(value, data) {
      if (!value) return true
      return data.fullName.indexOf(value) !== -1
    },
    getChecked(checkData) {
      const tree = this.$refs.tree
      if (!this.multiple) {
        tree.setCheckedKeys([checkData.id])
        this.handleData()
        this.$emit('input', this.checkValue[0])
      } else {
        this.handleData()
        this.$emit('input', this.checkValue)
      }

      
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .fade-enter-active {
    transition: all .3s ease;
  }
  .fade-leave-active {
    transition: all .3s;
  }
  .fade-enter {
    height: 0px;
  }
  .fade-enter-to {
    height: 200px;
    opacity: 1;
  }
  .fade-leave {
    height: 200px;
  }
  .fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    height: 0px;
    opacity: 0;
  }
  .org-tree {
    z-index: auto;
    .tree-data {
      background-color: white;
      overflow: hidden !important;
      /*position: absolute;*/
      width: 100%;
      height: 200px;
      margin-top: 6px;
      border: 1px solid #dcdfe6;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .filter-tree-text{
        padding: 10px;
      }
     }
  }
</style>
<style rel="stylesheet/scss" lang="scss">
  .org-tree {
  .tree-data {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .el-input__suffix {
      margin-right: 5px;
    }
   }
  }
</style>
