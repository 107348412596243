import img from 'static/images/menu/menu_vehicle.png'
export default [
  {
    path: 'vehicleList',
    name: 'vehicleList',
    icon: img,
    component: () => import(/* webpackChunkName: "vehicleList" */ './index')
  },
  // {
  //   parent: 'vehicleList',
  //   path: 'vehicleDetail',
  //   name: 'vehicleDetail',
  //   component: () => import(/* webpackChunkName: "vehicleDetail" */ './components/detail_bak.vue')
  // }
  // {
  //   parent: 'vehicle',
  //   path: 'vehicleDetail2',
  //   name: 'vehicleDetail2',
  //   component: () => import(/* webpackChunkName: "vehicleDetail2" */ './components/detail2.vue')
  // }
]
