import img from 'static/images/menu/menu_role.png'
export default [
  {
    path: 'role',
    name: 'role',
    icon: img,
    component: () => import(/* webpackChunkName: "role" */ './index')
  },
  {
    parent: 'role',
    path: 'roleSetting',
    name: 'roleSetting',
    component: () => import(/* webpackChunkName: "roleSetting" */ './roleSetting')
  }
]
