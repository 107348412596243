import Vue from 'vue'
import { defaultRoutes } from './router'
import { getCurrentSystem } from './router/util'
import store from './store'
import NProgress from 'nprogress' // progress bar
import Router from 'vue-router'
// import { MessageBox } from 'element-ui'
import { getStorage } from '@/libs/utils'

export const createRouter = routes => new Router({
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const whiteList = ['/login'] // 不重定向白名单
Vue.use(Router)

const router = new Router({
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: defaultRoutes
})

router.beforeEach(async(to, from, next) => {
  NProgress.configure({ showSpinner: false, parent: '#app' })// NProgress configuration
  NProgress.start()
  const token = getStorage('token')
  if (token) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (!store.getters.userInfo.id) {
        store.dispatch('GetUserInfo')
      }
      if (!store.getters.dicts || !store.getters.dicts.size) {
        store.dispatch('GetDict')
      }
      if (store.getters.menuList === null) {
        try {
          store.dispatch('GetResourceList').then(() => {
            next({ path: to.path })
          })
        } catch (e) {
          console.log(e)
        }
      } else {
        getCurrentSystem(to)
        next()
      }
    }
    store.dispatch('SetResourceId', to.meta.resourceId)
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})

export default router
