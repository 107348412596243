import Vue from 'vue'
import store from '@/store'

export const setOptionsFilter = (dicts) => {
  for (const [key, value] of dicts) {
    Vue.filter(key, generateFilter(value))
  }
}

export const getOptions = (key) => {
  const optionsRes = {}
  const keyType = Object.prototype.toString.call(key)
  if (keyType === '[object Array]') {
    for (let i = 0; i < key.length; i++) {
      const k = key[i]
      optionsRes[k] = store.getters.dicts.get(k)
    }
    return optionsRes
  }
  if (keyType === '[object String]') {
    return store.getters.dicts.get(key)
  }
}

function generateFilter(data) {
  return function(value) {
    const find = data.find(item => item.value === value)
    return find ? find.label : ''
  }
}
