import img from 'static/images/menu/menu_resource.png'

export default [
  {
    path: 'resource',
    name: 'resource',
    icon: img,
    component: () => import(/* webpackChunkName: "resource" */ '@/views/pages/configSystem/resource/index')
  }
]
