// set function parseTime,formatTime to filter
export { dateFormat, dateTimeFormat } from '@/libs/utils/index'

import store from '@/store'

function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/* 数字 格式化*/
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

function getDictByType(type) {
  let data = []
  if (store.state.dict.dicts) {
    data = store.state.dict.dicts[type] || []
  }
  return data || []
}
export function getDicts(val, type) {
  const findData = getDictByType(type).find((item) => {
    // eslint-disable-next-line
    return item.value == val
  })
  return findData
}
export function getDictLabel(val, type) {
  const findData = getDictByType(type).find((item) => {
    // eslint-disable-next-line
    return item.value == val
  })
  return findData ? findData.label : ''
}

export function numEncryption(value) {
  if (!value) return '--'
  let str = value.toString()
  const str1 = str.substring(0, 3)
  const str2 = str.substring(str.length - 4, str.length)
  str = str1 + '****' + str2
  return str
}

export function formatSeconds(value) {
  if (value === 0) return '0秒'
  if (!value || value === '--') return '--'
  let theTime = parseInt(value) // 秒
  let theTime1 = 0 // 分
  let theTime2 = 0 // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }
  let result = `${theTime}秒`
  if (theTime1 > 0) result = `${theTime1}分钟${result}`
  if (theTime2 > 0) result = `${theTime2}小时${result}`
  return result
}

export const fileSizeFormat = (value) => {
  if (value < 0) {
    return '未知'
  }
  let strInfo = value
  if (value) {
    const unitArr = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let index = 0
    const srcsize = parseFloat(value)
    index = Math.floor(Math.log(srcsize) / Math.log(1024))
    let size = srcsize / Math.pow(1024, index)
    size = size.toFixed(2) // 保留的小数位数
    strInfo = size + unitArr[index]
  }
  return strInfo
}
