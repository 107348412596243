<template>
  <QkForm ref="quickForm" :rules="rules" :model="form" :items="items" :btns="btns" label-width="120px" />
</template>

<script>
import { notNull, password } from '@/libs/utils/validate'
import { updatePassword } from '@/api/user'

export default {
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      rules: {
        oldPassword: notNull,
        newPassword: [
          notNull,
          password
        ],
        confirmNewPassword: [
          notNull,
          password,
          {
            validator: (rule, val, callback) => {
              if (this.form.newPassword !== val) {
                callback('两次输入的密码不一致')
              } else {
                callback()
              }
            }
          }
        ]
      },
      form: {
        userName: ''
      },
      items: [
        this.$qkUtils.formInput('oldPassword', '输入旧密码', { label: '旧密码', props: { type: 'password' }, span: 24 }),
        this.$qkUtils.formInput('newPassword', '必须包含大小写字母和数字，长度在6-18位', { label: '新密码', props: { type: 'password' }, span: 24 }),
        this.$qkUtils.formInput('confirmNewPassword', '必须包含大小写字母和数字，长度在6-18位', { label: '再次输入密码', props: { type: 'password' }, span: 24 })
      ],
      btns: [
        { text: '取消', click: this.cancel },
        { text: '确定', type: 'primary', click: this.submit }
      ]
    }
  },
  methods: {
    cancel() {
      this.onClose()
    },
    submit() {
      const { form } = this.$refs.quickForm.$refs
      form.validate(valid => {
        if (valid) {
          const payload = {
            oldPassword: this.form.oldPassword,
            newPassword: this.form.newPassword
          }
          payload.oldPassword = this.$utils.encryptPass(payload.oldPassword)
          payload.oldPassword = payload.oldPassword.toLocaleUpperCase()
          payload.newPassword = this.$utils.encryptPass(payload.newPassword)
          payload.newPassword = payload.newPassword.toLocaleUpperCase()
          updatePassword(payload).then(res => {
            this.$message.success('密码修改成功，重新登录')
            this.onAction('success')
            this.onClose()
          })
        }
      })
    }
  }
}
</script>
