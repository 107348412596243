<template>
  <div v-if="!item.hidden" class="menu-wrapper">
    <template v-if="noChild(item)">
      <app-link :to="resolvePath(onlyOneChild.fullPath)">
        <el-menu-item :index="resolvePath(onlyOneChild.fullPath)" :class="{'submenu-title-noDropdown':!isNest}">
          <item :icon="(onlyOneChild.meta && onlyOneChild.meta.icon) || (item.meta && item.meta.icon)" :selected-icon="item.meta.selectedIcon" :title="onlyOneChild.title" />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else :index="resolvePath(item.fullPath)">
      <template slot="title">
        <item :icon="item.meta.icon" :selected-icon="item.meta.selectedIcon" :title="item.title" />
      </template>

      <template v-for="child in item.children">
        <sidebar-item
          v-if="!child.hidden&&child.children&&child.children.length>0"
          :is-nest="true"
          :item="child"
          :key="child.fullPath"
          :base-path="resolvePath(child.fullPath)"
          class="nest-menu" />
        <app-link v-else :to="resolvePath(child.fullPath)" :key="child.name">
          <el-menu-item :index="resolvePath(child.fullPath)">
            <item :icon="child.meta && child.meta.icon" :title="child.title" />
          </el-menu-item>
        </app-link>
      </template>
    </el-submenu>

  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/libs/utils/validate'
import Item from './Item'
import AppLink from './Link'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      onlyOneChild: null
    }
  },
  created() {
  },
  methods: {
    noChild(parent) {
      if (!parent.children) {
        this.onlyOneChild = { ...parent }
        return true
      }
      if (parent.children.length > 0) {
        const showChild = parent.children.filter(item => !item.hidden)
        if (showChild.length > 0) {
          return false
        } else {
          this.onlyOneChild = { ...parent }
          return true
        }
      }
      return false
    },
    resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath
      }
      return path.resolve(this.basePath, routePath)
    },
    isExternalLink(routePath) {
      return isExternal(routePath)
    }
  }
}
</script>
