export default [
  {
    path: 'loginLog',
    name: 'loginLog',
    component: () => import(/* webpackChunkName: "loginLog" */ './index')
  }
  // {
  //   parent: 'sysVersion',
  //   path: 'sysVersionDetail',
  //   name: 'sysVersionDetail',
  //   component: () => import(/* webpackChunkName: "sysVersionDetail" */ './detail')
  // }
]
