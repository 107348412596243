<template>
  <div class="navbar">
    <div class="home-icon" @click="$router.push({ name: 'main' })">
      <i class="v2x-icon icon-home-filling"></i>
    </div>
    <Breadcrumb></Breadcrumb>
    <NavRight theme="light" select-mode="dropdown"/>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'

export default {
  components: {
    Breadcrumb
  },
  computed: {
    navTitle() {
      return this.$route.meta ? this.$route.meta.title : '广汽三菱车联网'
    }
  }
}
</script>
<style>
.el-badge__content.is-fixed{
  top: 15px !important;
  right: 20px !important;
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
@import '../../../styles/variables.scss';
.navbar {
  display: flex;
  position: absolute;
  top: 0;
  left: $sideBarWidth;
  z-index: 1;
  width: calc(100% - #{$sideBarWidth});
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  background-color: #F8F8F9;
  display: flex;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.12), 0 0 3px 0 rgba(0,0,0,.04);
  .home-icon {
    i {
      color: #828283;
      font-size: 20px!important;
      cursor: pointer;
    }
  }
  .nav-right {
    position: absolute;
    right: 20px;
  }
}
</style>
