import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getStorage } from '.'
// 同样提示在5秒内只提示一次
window.errorMsgArr = []
window.errorMsg = function(msg) {
  if (window.errorMsgArr.includes(msg)) {
    return
  }
  window.errorMsgArr.push(msg)
  Message({
    message: msg,
    type: 'error',
    duration: 3000
  })
  setTimeout(() => {
    window.errorMsgArr = window.errorMsgArr.filter(item => item !== msg)
  }, 200)
}

// window.logoutStatus = false
// 处理空传值、解决缓存问题
const handlerParams = (params) => {
  const data = { ...params }
  Object.keys(data).forEach(key => {
    if (data[key] === '' || data[key] === null || data[key] === undefined) {
      delete data[key]
    }
  })
  data.t = Date.now()
  return data
}
// 创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 30000, // 请求超时时间
  // `validateStatus` 定义对于给定的HTTP 响应状态码是 resolve 或 reject  promise 。如果 `validateStatus` 返回 `true` (或者设置为 `null` 或 `undefined`)，promise 将被 resolve; 否则，promise 将被 rejecte
  validateStatus: function(status) {
    return true // default
  }
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers.resourceId = store.state.app.resourceId
    config.headers.Authorization = getStorage('token')
    config.params = handlerParams(config.params)
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    const res = response.data
    // blob 请求直接返回数据
    if (response.config.responseType === 'blob') {
      return response.data
    }
    if (res.statusCode !== 200) {
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      // const errorCodes = ['USER_KICKED_OUT', 'SESSION_TIMEOUT', 'USER_DISABLED']
      const errorCodes = [401, 403]
      if (errorCodes.includes(res.statusCode)) {
        window.errorMsg(res.message || res.statusMessage || '服务器繁忙，稍后再试！')
        store.dispatch('FedLogOut').then(() => {
          setTimeout(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          }, 1000)
        })
        return Promise.reject(res)
      }
      console.log('请求正确，code不是SUCCESS')
      if (new RegExp('^50.*$').test(res.statusCode)) {
        window.errorMsg(res.message || res.statusMessage || res.data || '服务器繁忙，稍后再试！')
        return Promise.reject(res)
      }
      // 特殊处理后台返回的错误数据
      const message = res.message || res.statusMessage || res.data || '服务器繁忙，稍后再试！'
      window.errorMsg(message)
      return Promise.reject(res)
    } else {
      return response.data
    }
  },
  error => {
    // validateStatus 设置为true 不会进入请求错误处理逻辑
    if (error.message.indexOf('Network') !== -1) {
      setTimeout(() => {
        // 所有错误都转到登录页面
        store.dispatch('FedLogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      }, 1000)
    } else {
      window.errorMsg(error.message || '服务器繁忙，稍后再试！')
    }
    return Promise.reject(error)
  }
)

export default service
