import { defaultRoutes } from './index'
import router from '@/permission'
import Router from 'vue-router'
import store from '@/store'
// 创建新路由
const createRouter = routes => new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: routes || []
})

// 获取/views/pages下所有routes文件
export const getRoutes = () => {
  const routes = new Map()
  const req = require.context('../views/pages', true, /routes.js$/)
  req.keys().forEach(routePath => {
    const def = req(routePath).default
    def.forEach(route => {
      routes.set(route.name, route)
    })
  })
  return routes
}
const routesMap = getRoutes()

// 菜单数据过滤按钮
export const getMenuList = (systems) => {
  // 判断是否菜单
  // function isMenu(item) { return item.resourceType === 1 && item.menuType === 0 }
  // 过滤
  function filterData(childs) {
    const res = []
    for (let i = 0; i < childs.length; i++) {
      const item = childs[i]
      if (item.child) {
        item.children = filterData(item.child)
      }
      // if (isMenu(item)) {
      res.push(item)
      // }
    }
    return res
  }
  return filterData(systems)
}

// 处理路由数据
const handleMenuData = (systems, prePath = '/plat') => {
  return systems.map(item => {
    // 构建菜单数据
    const ret = {
      fullPath: prePath + '/' + item.resourcePath,
      path: item.resourcePath,
      name: item.resourcePath,
      meta: { resourceId: item.id, title: item.resourceName, icon: item.icon, selectedIcon: item.selectedIcon },
      title: item.resourceName
    }
    ret.component = routesMap.get(item.resourcePath) ? routesMap.get(item.resourcePath).component : () => import('@/noPage')
    ret.meta.icon = routesMap.get(item.resourcePath) ? routesMap.get(item.resourcePath).icon : ''
    if (item.child && item.child.length) {
      ret.component = () => import('@/routeView')
      ret.child = handleMenuData(item.child, ret.fullPath)
      ret.redirect = ret.fullPath + '/' + item.child[0].resourcePath
    }
    ret.children = ret.child
    return ret
  })
}
// 处理子级未显示路由数据
const handleChildMenuData = (systems, childMenus) => {
  const newChildMenus = []
  const copyChildMenus = [...childMenus]
  // 遍历所有菜单
  const loopMenus = (menus) => {
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i]
      const childs = copyChildMenus.filter(c => c.parent === menu.name)
      childs.forEach(() => {
        const index = copyChildMenus.findIndex(c => c.parent === menu.name)
        const child = copyChildMenus[index]
        if (child) {
          child.meta = {
            parentPath: menu.fullPath,
            title: child.title
          }
          child.path = menu.fullPath + '/' + child.path
          newChildMenus.push(child)
          copyChildMenus.splice(index, 1)
        }
      })
      if (menu.children) {
        loopMenus(menu.children)
      }
    }
  }
  loopMenus(systems)
  return newChildMenus
}
// 生成用户所有路由权限
export const generateRoutes = (systems) => {
  const menus = handleMenuData(systems)
  const allChildMenus = Array.from(routesMap.values()).filter(item => item.parent)
  const childMenus = handleChildMenuData(menus, allChildMenus)
  // 构建新菜单数据
  const routes = defaultRoutes
  routes[routes.length - 2].children = [...menus, ...childMenus]
  router.matcher = createRouter().matcher
  router.addRoutes(routes)
  return menus
}
// 获取当前路由所在系统 并设置菜单
export const getCurrentSystem = (to) => {
  // 通过路径去匹配 所属系统
  const currentSys = to.fullPath.split('/')[2]
  // if (!currentSys) return
  store.dispatch('SetActiveSystemData', { url: currentSys || '' })
}

// 改变系统
export const changeSystemMenu = (system) => {
  store.dispatch('SetActiveSystemData', system)
  router.push({ path: '/plat/' + system.resourcePath })
}
