const getters = {
  systems: state => state.user.systems,
  activeSystem: state => state.user.activeSystem,
  activeSystemData: state => state.user.activeSystemData,
  userInfo: state => state.user.userInfo,
  userId: state => state.user.userInfo.id,
  dicts: state => state.dict.dicts,
  menuList: state => state.user.menuList,
  waitDownloadNumber: state => state.user.waitDownloadNumber,
  videoCount: state => state.app.videoCount,
  sendParams: state => state.alarm.sendParams
}
export default getters
