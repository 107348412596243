<template>
  <!-- IconBtn -->
  <transition name="">
    <div
      v-btn-auth="authCode"
      style="display: inline-block">
      <el-tooltip
        v-model="mouseSelect"
        :content="content"
        :open-delay="500"
        :manual="true"
        :disabled="!content"
        placement="top">
        <div
          :class="classs"
          :style="styles"
          class="newIconBtn"
          @click="clickBtn($event)"
          @mouseenter="mouseEnter"
          @mouseleave="mouseLeave">
          <svg-icon
            :icon-class="icon"/>
        </div>
      </el-tooltip>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14'
    },
    color: {
      type: String,
      default: '#2A6AFF'
    },
    backgroundColor: {
      type: String,
      default: '#DFEAFD'
    },
    authCode: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: false
    },
    borderColor: {
      type: String,
      default: ''
    },
    borderHoverColor: {
      type: String,
      default: '#409eff'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mouseSelect: false
    }
  },
  computed: {
    classs() {
      return {
        'btn-is-focus': this.mouseSelect && this.border,
        'icon-disabled': this.disabled
      }
    },
    styles() {
      var style = {
        fontSize: this.size + 'px',
        backgroundColor: this.backgroundColor,
        color: this.color,
        borderColor: this.borderColor,
        borderWidth: this.borderColor ? '1px' : 0,
        marginRight: '5px',
        marginBottom: '3px'
      }
      return style
    }
  },
  methods: {
    clickBtn($event) {
      if (this.disabled) return
      this.$emit('click', $event)
    },
    mouseEnter() {
      this.mouseSelect = true
    },
    mouseLeave() {
      this.mouseSelect = false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import '../../styles/variables.scss';

.newIconBtn {
  background-color: $btnBg;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  white-space: nowrap;
  line-height: 1.5;
  user-select: none;
  padding: 0px 1px;
  font-size: 12px;
  border-radius: 2px;
  transition: color 0.2s linear, border 0.2s linear, background-color 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
  color: #2A6AFF;
  padding: 2px 5px;
}
.icon-disabled {
  cursor:no-drop;
}
.btn-is-focus {
  // color: #409eff !important;
  border: 1px solid #409eff;
}
</style>
